<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <!--  -->
    <v-row
      align="center"
      justify="center"
      class="d-none d-sm-flex"
    >
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="warning"
          icon="mdi-account"
          title="Anggota"
          :value="String(Sumarry.total)"
          style="cursor: pointer;"
          @click.native="setStatus('')"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="rgb(233, 30, 99)"
          icon="mdi-account-multiple-plus"
          title="Menunggu"
          :value="String(Sumarry.pending)"
          style="cursor: pointer;"
          @click.native="setStatus('pending')"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="success"
          icon="mdi-account-settings"
          title="Aktif"
          :value="String(Sumarry.active)"
          style="cursor: pointer;"
          @click.native="setStatus('active')"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-account-off"
          title="Rejected"
          :value="String(Sumarry.rejected)"
          style="cursor: pointer;"
          @click.native="setStatus('rejected')"
        />
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
      class="d-flex d-sm-none"
    >
      <!--  -->
      <v-col
        cols="12"
        style="padding: 0"
      >
        <!--  -->
        <v-tabs
          v-model="tab"
          show-arrows
        >
          <v-tab
            v-for="item in items"
            :key="item.tab"
          >
            <v-badge
              color="green"
              :content="item.Summary"
            >
              {{ item.title }}
            </v-badge>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item in items"
            :key="item.tab"
            color="red"
          >
            <v-card flat>
              <v-card-text>
                <v-col
                  cols="12"
                  sm="6"
                  lg="3"
                >
                  <base-material-stats-card
                    :color="item.color"
                    :icon="item.icon"
                    :title="item.title"
                    :value="String(item.Summary)"
                    style="cursor: pointer;"
                    @click.native="setStatus(item.status)"
                  />
                </v-col>
                {{ item.content }}
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <!--  -->
    <v-row>
      <v-col
        cols="12"
        md="4"
        lg="4"
      >
        <v-text-field
          v-model="q"
          append-icon="mdi-magnify"
          :label="$t('search')"
          color="secondary"
          hide-details
          @keyup="Search"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        md="2"
        lg="2"
        offset-md="6"
        offset-lg="6"
      >
        <v-btn
          to="/anggota/create"
          left
          width="100%"
          color="primary"
        >
          <span class="mr-1">
            <v-icon light>mdi-pencil-plus</v-icon>
          </span>
          Buat Baru
        </v-btn>
      </v-col>
    </v-row>
    <!--  -->
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="12"
      >
        <base-material-card
          icon="mdi-account"
          color="primary"
          class="px-6 py-3"
          :title="status !== '' ? 'Anggota - ' + status : 'Anggota - All'"
          style="text-transform: capitalize"
        >
          <template>
            <div
              v-if="!isLoading"
              class="text-center mt-5 mb-5"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </div>
            <div v-else>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left primary--text">
                        #
                      </th>
                      <th class="text-left primary--text">
                        Nama
                      </th>
                      <th class="text-left primary--text">
                        Email
                      </th>
                      <th class="text-left primary--text">
                        No.Ponsel
                      </th>
                      <th class="text-left primary--text">
                        Area/cabang
                      </th>
                      <th class="text-left primary--text">
                        Terdaftar Pada
                      </th>
                      <th class="text-left primary--text">
                        Keterangan
                      </th>
                      <th class="text-left primary--text">
                        Status
                      </th>
                      <th class="text-center primary--text">
                        Aksi
                      </th>
                    </tr>
                  </thead>
                  <!--  -->
                  <tbody>
                    <tr
                      v-for="(anggota, index) in Anggota.data"
                      :key="anggota.id"
                    >
                      <td>{{ index + Anggota.meta.from }}</td>
                      <td>{{ anggota.name }}</td>
                      <td>{{ anggota.email }}</td>
                      <td>{{ anggota.phone }}</td>
                      <td>
                        {{
                          anggota.region != null ? anggota.region.name : ' - '
                        }}
                      </td>
                      <td>{{ anggota.created_at | moment('D MMM YYYY') }}</td>
                      <td tyle="text-transform: capitalize">
                        {{ anggota.note ? anggota.note : ' - ' }}
                      </td>
                      <td tyle="text-transform: capitalize">
                        {{ anggota.status }}
                      </td>
                      <td
                        v-if="
                          status === '' ||
                            status === 'active' ||
                            status === 'rejected'
                        "
                      >
                        <v-tooltip
                          bottom
                          color="primary"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <!--  -->
                            <v-btn
                              icon
                              v-bind="attrs"
                              :to="'/anggota/' + anggota.id + '/edit'"
                              v-on="on"
                            >
                              <v-icon color="primary">
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip
                          bottom
                          color="warning"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <!--  -->
                            <v-btn
                              icon
                              v-bind="attrs"
                              @click="sendReset(anggota)"
                              v-on="on"
                            >
                              <v-icon color="warning">
                                mdi-lock-reset
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Reset Security Code</span>
                        </v-tooltip>
                        <v-tooltip
                          bottom
                          color="red"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <!--  -->
                            <v-btn
                              icon
                              v-bind="attrs"
                              @click="openDialogD(anggota.id, anggota.name)"
                              v-on="on"
                            >
                              <v-icon color="red">
                                mdi-close-octagon
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </td>
                      <td v-else-if="status === 'pending'">
                        <!--  -->
                        <v-tooltip
                          bottom
                          color="green"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="openDialog(anggota.id, anggota.name)"
                            >
                              <v-icon color="green">
                                mdi-checkbox-marked-circle-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>approve</span>
                        </v-tooltip>
                        <v-tooltip
                          bottom
                          color="red"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="openDialogR(anggota.id, anggota.name)"
                            >
                              <v-icon color="red">
                                mdi-close-octagon
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Rejected</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div class="text-center">
                <v-pagination
                  v-model="page"
                  :length="Anggota.meta.last_page"
                  total-visible="5"
                  @input="getResults"
                />
              </div>
            </div>
          </template>
          <v-col cols="12">
            <template>
              <v-dialog
                v-model="dialog.open"
                width="500"
                :retain-focus="false"
              >
                <v-card>
                  <v-card-title primary-title>
                    Confirmation
                  </v-card-title>

                  <v-card-text>
                    Anda yakin akan Menyetujui akun ini dengan nama "{{
                      dialog.title
                    }}"?
                  </v-card-text>

                  <v-divider />

                  <v-card-actions>
                    <v-btn
                      color="error"
                      @click="dialog.open = false"
                    >
                      BATAL
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="approve(dialog.id)"
                    >
                      Approve
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-col>
          <v-col cols="12">
            <template>
              <v-dialog
                v-model="dialogR.open"
                width="500"
                :retain-focus="false"
              >
                <v-card>
                  <v-card-title primary-title>
                    Confirmation
                  </v-card-title>

                  <v-card-text>
                    Anda yakin Menolak Akun Dengan Nama "{{ dialogR.title }}"?
                  </v-card-text>

                  <v-divider />

                  <v-card-actions>
                    <v-btn
                      color="error"
                      @click="dialogR.open = false"
                    >
                      BATAL
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="rejected(dialogR.id)"
                    >
                      Rejected
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-col>
          <v-col cols="12">
            <template>
              <v-dialog
                v-model="dialogD.open"
                width="500"
                :retain-focus="false"
              >
                <v-card>
                  <v-card-title primary-title>
                    Confirmation
                  </v-card-title>

                  <v-card-text>
                    Anda yakin Menolak Akun Dengan Nama "{{ dialogD.title }}"?
                  </v-card-text>

                  <v-divider />

                  <v-card-actions>
                    <v-btn
                      color="error"
                      @click="dialogD.open = false"
                    >
                      BATAL
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="delUser(dialogD.id)"
                    >
                      Delete
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-col>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'Anggota',
    data () {
      return {
        loading4: false,
        Anggota: {
          data: {},
          meta: {},
        },
        Sumarry: {
          total: 0,
          pending: 0,
          active: 0,
          rejected: 0,
        },
        tab: null,
        items: [
          {
            tab: 'One',
            title: 'Anggota',
            icon: 'mdi-account',
            Summary: '0',
            status: '',
            color: 'warning',
            tabColor: 'warning',
          },
          {
            tab: 'Two',
            title: 'menunggu',
            icon: 'mdi-account-multiple-plus',
            Summary: '0',
            status: 'pending',
            color: 'rgb(233, 30, 99)',
            tabColor: 'rgb(233,30,99)',
          },
          {
            tab: 'Three',
            title: 'Aktif',
            icon: 'mdi-account-settings',
            Summary: '0',
            status: 'active',
            color: 'success',
            tabColor: 'success',
          },
          {
            tab: 'Four',
            title: 'Rejected',
            icon: 'mdi-account-off',
            Summary: '0',
            status: 'rejected',
            color: 'info',
            tabColor: 'info',
          },
        ],
        dialog: {
          open: false,
          id: 0,
          title: '',
        },
        dialogR: {
          open: false,
          id: 0,
          title: '',
        },
        dialogD: {
          open: false,
          id: 0,
          title: '',
        },
        isLoading: false,
        page: parseInt(this.$route.query.page) || 1,
        q: this.$route.query.q || '',
        status: this.$route.query.status || '',
        per_page: this.$route.query.per_page || 10,
        offset: this.$route.query.offset || 10,
        filter_new: this.$route.query.filter_new || 1,
      }
    },
    watch: {
      status (newVal) {
        this.$router.push({ query: { ...this.$route.query, status: newVal } })
      },
      '$route.query.status': function (val) {
        this.status = val
      },
      page (newVal) {
        this.$router.push({ query: { ...this.$route.query, page: newVal } })
      },
      '$route.query.page': function (val) {
        this.page = parseInt(val)
      },
      offset (newVal) {
        this.$router.push({ query: { ...this.$route.query, offset: newVal } })
      },
      '$route.query.offset': function (val) {
        this.offset = parseInt(val)
      },
      per_page (newVal) {
        this.$router.push({ query: { ...this.$route.query, per_page: newVal } })
      },
      '$route.query.per_page': function (val) {
        this.per_page = parseInt(val)
      },
      filter_new (newVal) {
        this.$router.push({ query: { ...this.$route.query, filter_new: newVal } })
      },
      '$route.query.filter_new': function (val) {
        this.filter_new = parseInt(val)
      },
      q (newVal) {
        this.$router.push({ query: { ...this.$route.query, q: newVal } })
      },
      '$route.query.q': function (val) {
        this.q = val
      },
      suspend (newVal) {
        this.$router.push({ query: { ...this.$route.query, suspend: newVal } })
      },
      'this.$route.query.suspend': function (val) {
        this.suspend = val
      },
    },
    mounted () {
      this.getSummary()
      this.getResults()
    },
    methods: {
      sendReset (user) {
        axios
          .post('/v1/user/password/reset/wa/', {
            phone: user.phone,
            community_id: user.community_id,
          })
          .then(response => {
            if (response.data.meta.status) {
              this.dialogD.open = false
              this.$toast.success(
                'Security Code reset sudah dikirim ke Whatsapp yang sudah terdaftar',
                {
                  type: 'success',
                  position: 'top-right',
                  duration: 3000,
                  dismissible: true,
                },
              )
              // Update
              this.getResults()
            } else {
              this.dialogD.open = false
              this.$toast.error(response.data.meta.message, {
                type: 'error',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            }
          })
      },
      delUser (id) {
        axios.delete('/v1/user/' + id).then(response => {
          if (response.data.meta.status) {
            this.dialogD.open = false
            this.$toast.error('Berhasil menghapus data', {
              type: 'error',
              position: 'top-right',
              duration: 3000,
              dismissible: true,
            })
            // Update
            this.getResults()
          }
        })
      },
      openDialog (id, name) {
        this.dialog.id = id
        this.dialog.title = name
        this.dialog.open = true
      },
      openDialogR (id, name) {
        this.dialogR.id = id
        this.dialogR.title = name
        this.dialogR.open = true
      },
      openDialogD (id, name) {
        this.dialogD.id = id
        this.dialogD.title = name
        this.dialogD.open = true
      },
      rejected (id) {
        axios
          .post('/v1/user/' + id + '/verify', {
            status: 'rejected',
          })
          .then(response => {
            if (response.data.meta.status) {
              this.dialogR.open = false
              this.$toast.error('Akun Berhasil Di tolak ', {
                type: 'error',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
              // Update
              this.getSummary()
              this.getResults()
            }
          })
      },
      approve (id) {
        axios
          .post('/v1/user/' + id + '/verify', {
            status: 'verified',
          })
          .then(response => {
            if (response.data.meta.status) {
              this.dialog.open = false
              this.$toast.success('Berhasil Menyetujui Akun', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
              // update
              this.getSummary()
              this.getResults()
            }
          })
          .catch(response => {
            console.log(response)
          })
      },
      setStatus (val) {
        this.status = val
        this.getResults()
      },
      setAnggota (data) {
        this.Anggota = data
      },
      getAnggota () {
        axios.get('/v1/user?per_page=10&entities=region').then(response => {
          this.setAnggota(response.data)
        })
      },
      setSummary (data) {
        this.Sumarry = data
        this.items.forEach(item => {
          if (item.status === '') {
            item.Summary = this.Sumarry.total
          } else if (item.status === 'pending') {
            item.Summary = this.Sumarry.pending
          } else if (item.status === 'active') {
            item.Summary = this.Sumarry.active
          } else if (item.status === 'rejected') {
            item.Summary = this.Sumarry.rejected
          } else {
            item.Summary = 0
          }
        })
      },
      getSummary () {
        axios.get('/v1/user/summary').then(response => {
          this.setSummary(response.data.data)
        })
      },
      Search () {
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        this.timer = setTimeout(() => {
          this.getResults()
        }, 700)
      },
      getResults (page = this.page) {
        this.isLoading = false
        if (this.status === '') {
          const params = {
            q: this.q,
            page: this.page,
            per_page: this.per_page,
            entities: 'region',
            offset: this.offset,
            sort: '-id',
          }
          axios.get('/v1/user?', { params: params }).then(response => {
            this.setAnggota(response.data)
            this.isLoading = true
          })
        } else if (this.status === 'active') {
          const params = {
            q: this.q,
            page: this.page,
            per_page: this.per_page,
            entities: 'region',
            sort: '-id',
            active: 5,
          }
          axios
            .get('/v1/user?', { params: params })
            .then(response => {
              this.setAnggota(response.data)
              this.isLoading = true
            })
            .catch(E => {
              console.error(E)
            })
        } else if (this.status === 'pending') {
          const params = {
            q: this.q,
            page: this.page,
            per_page: this.per_page,
            entities: 'region',
            status: 'pending',
          }
          axios
            .get('/v1/user?', { params: params })
            .then(response => {
              this.setAnggota(response.data)
              this.isLoading = true
            })
            .catch(E => {
              console.error(E)
            })
        } else if (this.status === 'rejected') {
          const params = {
            q: this.q,
            page: this.page,
            per_page: this.per_page,
            entities: 'region',
            status: 'rejected',
          }
          axios
            .get('/v1/user?', { params: params })
            .then(response => {
              this.setAnggota(response.data)
              this.isLoading = true
            })
            .catch(E => {
              console.error(E)
            })
        }
      },
    },
  }
</script>
